


























import { formatRupiah } from "@/apps/core/modules/utils/text";
import { computed, defineComponent, reactive } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import NasabahVM, { Nasabah } from "../models/nasabah";
import {
  toUserDateFormat,
  toUserDateTimeFormat,
} from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "UserInfo",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
  },
  props: {
    total: [String, Number],
    month: String,
  },
  setup() {
    const me = accountStateRef.me;
    const bgImageUrl = require("@/apps/nasabah/assets/img/home_bg.png");
    const nasabahVM = new NasabahVM();
    const nasabah = reactive(nasabahVM.instance);
    if (me.nasabah) {
      nasabahVM.fetch(me.nasabah.id);
    }

    const imageUrl = computed(() => {
      const foto = (nasabah as Nasabah).foto;
      return foto ? foto : "";
    });

    return {
      // Data
      bgImageUrl,
      formatRupiah,
      imageUrl,
      nasabah,

      // Method
      toUserDateFormat,
      toUserDateTimeFormat,
    };
  },
});
